<template>
  <div>
    <banner :title="title" :subtitle="subtitle"></banner>
  </div>
</template>

<script>
import Banner from "../components/NormalBanner.vue";
export default {
  components: { Banner },
  data() {
    return {
      title: "客户服务",
      subtitle: "提供软件定制开发、系统集成、服务外包、IT教育培训、ERP实施与咨询服务",
    };
  },
};
</script>

<style>
</style>